module.exports = {
    theme: 'light',
    accent: 'gray',
    location: 'bottom',
    cookiesPolicyUrl: {
        en: 'https://www.binarysoftware.es/cookies',
        es: 'https://www.binarysoftware.es/cookies',
    },
    persistentButton: false,
    services: {
        googleTagManager: {
            label: 'Google Tag Manager',
            code: 'GTM-KNQM2C',
            inlinejs: "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '$$code$$' );",
            noscript: '<iframe src="https://www.googletagmanager.com/ns.html?id=$$code$$" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
            lightboxcodetit: 'googletagmanager-tit',
            lightboxcodedesc: 'googletagmanager-desc'
        }
    }
}